<template>
  <div class="products">
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('單據') }}</h2>
      </div>
      <div class="invoices-list">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 053126941</h5>
            <p class="card-text">結帳時間： 2024-07-02 11:15:5</p>
            <p class="card-text">消費菜品： 共1份</p>
            <p class="card-text">實付金額： $50</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 142835118</h5>
            <p class="card-text">結帳時間： 2024-07-02 11:23:4</p>
            <p class="card-text">消費菜品： 共2份</p>
            <p class="card-text">實付金額： $120</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 203133739</h5>
            <p class="card-text">結帳時間： 2024-07-02 12:05:10</p>
            <p class="card-text">消費菜品： 共3份</p>
            <p class="card-text">實付金額： $150</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 321451662</h5>
            <p class="card-text">結帳時間： 2024-07-02 15:15:0</p>
            <p class="card-text">消費菜品： 共1份</p>
            <p class="card-text">實付金額： $40</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 457735033</h5>
            <p class="card-text">結帳時間： 2024-07-02 16:03:50</p>
            <p class="card-text">消費菜品： 共1份</p>
            <p class="card-text">實付金額： $45</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 496007703</h5>
            <p class="card-text">結帳時間： 2024-07-02 16:13:5</p>
            <p class="card-text">消費菜品： 共1份</p>
            <p class="card-text">實付金額： $50</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 991244061</h5>
            <p class="card-text">結帳時間： 2024-07-02 16:17:12</p>
            <p class="card-text">消費菜品： 共1份</p>
            <p class="card-text">實付金額： $60</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 730672899</h5>
            <p class="card-text">結帳時間： 2024-07-02 16:20:15</p>
            <p class="card-text">消費菜品： 共5份</p>
            <p class="card-text">實付金額： $552</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 195070751</h5>
            <p class="card-text">結帳時間： 2024-07-02 16:30:22</p>
            <p class="card-text">消費菜品： 共3份</p>
            <p class="card-text">實付金額： $35</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 903413945</h5>
            <p class="card-text">結帳時間： 2024-07-02 16:43:2</p>
            <p class="card-text">消費菜品： 共2份</p>
            <p class="card-text">實付金額： $70</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 995912978</h5>
            <p class="card-text">結帳時間： 2024-07-02 16:55:4</p>
            <p class="card-text">消費菜品： 共1份</p>
            <p class="card-text">實付金額： $35</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 675213759</h5>
            <p class="card-text">結帳時間： 2024-07-02 17:02:15</p>
            <p class="card-text">消費菜品： 共1份</p>
            <p class="card-text">實付金額： $65</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 875008015</h5>
            <p class="card-text">結帳時間： 2024-07-03 11:00:0</p>
            <p class="card-text">消費菜品： 共1份</p>
            <p class="card-text">實付金額： $40</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 457735033</h5>
            <p class="card-text">結帳時間： 2024-07-03 11:15:15</p>
            <p class="card-text">消費菜品： 共1份</p>
            <p class="card-text">實付金額： $50</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 590826851</h5>
            <p class="card-text">結帳時間： 2024-07-03 18:00:0</p>
            <p class="card-text">消費菜品： 共1份</p>
            <p class="card-text">實付金額： $75</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 668187094</h5>
            <p class="card-text">結帳時間： 2024-07-03 18:30:2</p>
            <p class="card-text">消費菜品： 共1份</p>
            <p class="card-text">實付金額： $100</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 765119747</h5>
            <p class="card-text">結帳時間： 2024-07-03 15:00:1</p>
            <p class="card-text">消費菜品： 共2份</p>
            <p class="card-text">實付金額： $120</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 830838515</h5>
            <p class="card-text">結帳時間： 2024-07-03 12:35:8</p>
            <p class="card-text">消費菜品： 共1份</p>
            <p class="card-text">實付金額： $80</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 976703400</h5>
            <p class="card-text">結帳時間： 2024-07-04 16:00:9</p>
            <p class="card-text">消費菜品： 共1份</p>
            <p class="card-text">實付金額： $110</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 595585107</h5>
            <p class="card-text">結帳時間： 2024-07-04 16:00:9</p>
            <p class="card-text">消費菜品： 共1份</p>
            <p class="card-text">實付金額： $110</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 155925267</h5>
            <p class="card-text">結帳時間： 2024-07-04 16:00:9</p>
            <p class="card-text">消費菜品： 共1份</p>
            <p class="card-text">實付金額： $110</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 801870140</h5>
            <p class="card-text">結帳時間： 2024-07-04 16:00:9</p>
            <p class="card-text">消費菜品： 共1份</p>
            <p class="card-text">實付金額： $110</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 248897454</h5>
            <p class="card-text">結帳時間： 2024-07-05 16:00:9</p>
            <p class="card-text">消費菜品： 共1份</p>
            <p class="card-text">實付金額： $110</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 546894960</h5>
            <p class="card-text">結帳時間： 2024-07-05 16:00:9</p>
            <p class="card-text">消費菜品： 共1份</p>
            <p class="card-text">實付金額： $110</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 228992735</h5>
            <p class="card-text">結帳時間： 2024-07-05 16:00:9</p>
            <p class="card-text">消費菜品： 共1份</p>
            <p class="card-text">實付金額： $110</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 571639510</h5>
            <p class="card-text">結帳時間： 2024-07-05 16:00:9</p>
            <p class="card-text">消費菜品： 共1份</p>
            <p class="card-text">實付金額： $110</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 976703400</h5>
            <p class="card-text">結帳時間： 2024-07-06 16:00:9</p>
            <p class="card-text">消費菜品： 共1份</p>
            <p class="card-text">實付金額： $110</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 165047459</h5>
            <p class="card-text">結帳時間： 2024-07-06 16:00:9</p>
            <p class="card-text">消費菜品： 共1份</p>
            <p class="card-text">實付金額： $110</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 408407404</h5>
            <p class="card-text">結帳時間： 2024-07-06 16:00:9</p>
            <p class="card-text">消費菜品： 共1份</p>
            <p class="card-text">實付金額： $110</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 254211184</h5>
            <p class="card-text">結帳時間： 2024-07-07 16:00:9</p>
            <p class="card-text">消費菜品： 共1份</p>
            <p class="card-text">實付金額： $110</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 291929099</h5>
            <p class="card-text">結帳時間： 2024-07-07 16:00:9</p>
            <p class="card-text">消費菜品： 共1份</p>
            <p class="card-text">實付金額： $110</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">單據編號： 466306696</h5>
            <p class="card-text">結帳時間： 2024-07-07 16:00:9</p>
            <p class="card-text">消費菜品： 共1份</p>
            <p class="card-text">實付金額： $110</p>
            <a href="#" class="btn btn-primary">單據詳情</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';

export default {
  name: 'Seats',
  mounted(){
  },
  data(){
    return {
      seats: [],
    }
  },
  computed: mapState({
   langcode: state => state.langcode,
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
.invoices-list{
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
  .card{
    margin-bottom: 30px;
    margin-right: 20px;
    .btn-primary{
      margin-right: 30px;
    }
  }
}
</style>
